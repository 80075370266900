import React from "react"
import { Card, CardBody } from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout pageTitle="Privacy Policy">
    <SEO title="Privacy Policy" />
    <Card>
      <CardBody className="marginated">
        <div className="padded">
          <h2>Welcome to our Privacy Policy</h2>
          <p>
            It is Highvoltagecode.com's policy to respect your privacy regarding
            any information we may collect while operating our website. This
            Privacy Policy applies to{" "}
            <a href="https://www.highvoltagecode.com">
              https://www.highvoltagecode.com
            </a>{" "}
            (hereinafter, "us", "we", or "https://www.highvoltagecode.com"). We
            respect your privacy and are committed to protecting personally
            identifiable information you may provide us through the Website. We
            have adopted this privacy policy ("Privacy Policy") to explain what
            information may be collected on our Website, how we use this
            information, and under what circumstances we may disclose the
            information to third parties. This Privacy Policy applies only to
            information we collect through the Website and does not apply to our
            collection of information from other sources.
          </p>
          <p>
            This Privacy Policy, together with the{" "}
            <a href="/terms">Terms of Service</a> posted on our Website, set
            forth the general rules and policies governing your use of our
            Website. Depending on your activities when visiting our Website, you
            may be required to agree to additional terms and conditions.
          </p>
          <h2>Website Visitors</h2>
          <p>
            Like most website operators, Highvoltagecode.com collects
            non-personally-identifying information of the sort that web browsers
            and servers typically make available, such as the browser type,
            language preference, referring site, and the date and time of each
            visitor request. Highvoltagecode.com's purpose in collecting
            non-personally identifying information is to better understand how
            Highvoltagecode.com's visitors use its website. From time to time,
            Highvoltagecode.com may release non-personally-identifying
            information in the aggregate, e.g., by publishing a report on trends
            in the usage of its website.
          </p>
          <h2>Gathering of Personally-Identifying Information</h2>
          <p>
            Certain visitors to Highvoltagecode.com's websites choose to
            interact with Highvoltagecode.com in ways that require
            Highvoltagecode.com to gather personally-identifying information.
            The amount and type of information that Highvoltagecode.com gathers
            depends on the nature of the interaction. For example, we ask
            visitors to provide an email address to sign up for a newsletter at
            https://www.highvoltagecode.com.
          </p>
          <h2>Security</h2>
          <p>
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
          </p>
          <h2>Cookies and Web Beacons</h2>
          <p>
            To enrich and perfect your online experience, Highvoltagecode.com
            uses "Cookies", similar technologies and services provided by others
            to display personalized content, appropriate advertising and store
            your preferences on your computer.
          </p>
          <p>
            A cookie is a string of information that a website stores on a
            visitor's computer, and that the visitor's browser provides to the
            website each time the visitor returns. Highvoltagecode.com uses
            cookies to help Highvoltagecode.com identify and track visitors,
            their usage of https://www.highvoltagecode.com, and their website
            access preferences. Highvoltagecode.com visitors who do not wish to
            have cookies placed on their computers should set their browsers to
            refuse cookies before using Highvoltagecode.com's websites, with the
            drawback that certain features of Highvoltagecode.com's websites may
            not function properly without the aid of cookies.{" "}
          </p>
          <p>
            Third-party service providers may also place and read cookies on
            your browser and/or use web beacons to collect information.
            highvoltagecode.com has no access or control over these cookies. You
            should review the respective privacy policies on any and all
            third-party parties for more information regarding their practices
            and how to opt-out.
          </p>
          <p>
            By continuing to navigate our website without changing your cookie
            settings, you hereby acknowledge and agree to use of cookies.
          </p>
          <h2>Advertisements</h2>
          <p>
            Ads appearing on our website may be delivered to visitors by
            advertising partners, who may set cookies. These cookies allow the
            third-party vendors to recognize your computer each time they send
            you an online advertisement to compile information about you or
            others who use your computer. This information allows third-party
            vendors to, among other things, deliver targeted advertisements that
            they believe will be of most interest to you. You can opt out of a
            third-party vendor's use of cookies for personalized advertising by
            visiting <a href="http://www.aboutads.info">www.aboutads.info</a>{" "}
          </p>
          <p>
            This Privacy Policy covers the use of cookies by Highvoltagecode.com
            and does not cover the use of cookies by any advertisers.
          </p>
          <h2>Links To External Sites</h2>
          <p>
            Our Service may contain links to external sites that are not
            operated by us. If you click on a third party link, you will be
            directed to that third party's site. We strongly advise you to
            review the Privacy Policy and terms and conditions of every site you
            visit.
          </p>
          <p>
            We have no control over, and assume no responsibility for the
            content, privacy policies or practices of any third party sites,
            products or services.
          </p>
          <h2>Third Party Service Providers</h2>
          <h4 className="align-text">Google</h4>
          <p>
            This site uses{" "}
            <a href="https://analytics.google.com/">Google Analytics</a> to
            collect information about the use of our site. Google Analytics
            collects information such as how often users visit our site, what
            pages they visit when they do so, and what other sites they used
            prior to coming to our site. We use the information we get from
            Google Analytics to improve our site. Google Analytics collects only
            the IP address assigned to you on the date you visit our site,
            rather than your name or other identifying information. We do not
            combine the information collected through the use of Google
            Analytics with personally identifiable information. Although Google
            Analytics plants a permanent cookie on your web browser to identify
            you as a unique user the next time you visit our site, the cookie
            cannot be used by anyone but Google. Google’s ability to use and
            share information collected by Google Analytics about your visits to
            our site is restricted by the Google Analytics Terms of Use.{" "}
            <a href="https://marketingplatform.google.com/about/analytics/terms/us/">
              http://www.google.com/analytics/terms/us/
            </a>{" "}
            You can prevent Google Analytics from recognizing you on return
            visits to this site by disabling cookies on your browser:{" "}
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a> </p>


             {/*<p>This site also uses <a href="https://www.google.com/adsense">Google Adsense</a> to serve ads.</p> */}


            <br></br>
            <br></br>
            <p>To learn more about how Google uses collected data, visit this page: <a href="https://policies.google.com/technologies/partner-sites?_ga=2.237220224.1419675201.1578072954-370188930.1577028129&_gac=1.251961979.1577206245.CjwKCAiAi4fwBRBxEiwAEO8_HkSDrE4kDrhrA5ioF09qz0_pc2NfkUCqIw6HFHBQfIzDdn2Feg7B5BoCgncQAvD_BwE">How Google uses information from sites or apps that use our services</a>. </p>
          
          <h4 className="align-text">AliExpress</h4>
          <p>
            This site uses <a href="https://www.aliexpress.com/">AliExpress</a>{" "}
            affiliate links and does earn a commission from certain links. This
            does not affect your purchases or the price you may pay.
          </p>
          <h4 className="align-text">Mailchimp</h4>
          This site uses <a href="https://mailchimp.com/">MailChimp</a> to
          collect and store your email address. You may opt out of the
          collection of your data by unsubscribing.
          <h4 className="align-text">CommentBox</h4>
          This site uses <a href="https://commentbox.io/">CommentBox</a> as a comment system provider.
          <p></p>
          <h4 className="align-text">Netlify</h4>
          <p>This site is hosted by <a href="https://www.netlify.com">Netlify</a>. This site also uses Netlify's forms to accept submissions through our contact form.</p>
          <h2>Aggregated Statistics</h2>
          <p>
            Highvoltagecode.com may collect statistics about the behavior of
            visitors to its website. Highvoltagecode.com may display this
            information publicly or provide it to others. However,
            Highvoltagecode.com does not disclose your personally-identifying
            information.
          </p>
          <h2>General Data Protection Regulation(GDPR)</h2>
          <p>
            If you are a citizen of European Union (EU) or the European Economic
            Area (EEA), you have certain data protection rights. If you wish to
            be informed what Personal Information we hold about you and if you
            want it to be removed from our systems, please contact us.
          </p>
          <h2>Privacy Policy Changes</h2>
          <p>
            Although most changes are likely to be minor, Highvoltagecode.com
            may change its Privacy Policy from time to time, and in
            Highvoltagecode.com's sole discretion. Highvoltagecode.com
            encourages visitors to frequently check this page for any changes to
            its Privacy Policy. Your continued use of this site after any change
            in this Privacy Policy will constitute your acceptance of such
            change.
          </p>
          <h2>Credit & Contact Information</h2>
          <p>
            This privacy policy was created with the help of{" "}
            <a
              href="termsandconditionstemplate.com/privacy-policy-generator/"
              
              
            >
              https://www.termsandconditionstemplate.com
            </a>
            . If you have any questions about this Privacy Policy, please{" "}
            contact us by using our <a href="/contact">contact form</a> or by <a href="mailto:onixaz@gmail.com">email</a>.
          </p>
        </div>
      </CardBody>
    </Card>
  </Layout>
)

export default PrivacyPage
